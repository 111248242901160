import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-8 text-white">
      <h1 className="text-3xl font-bold text-center mb-6">Privacy Policy for ChariParking</h1>
      <p>Last updated: May 04, 2024</p>

      <section>
        <h2 className="text-2xl font-semibold mt-4">1. Introduction</h2>
        <p>At ChariParking, one of our main priorities is visitor privacy. This Privacy Policy explains the information we collect and how we use it. </p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-4">2. Information We Collect</h2>
        <p>We collect personal information when you provide it or contact us directly. This may include name, email, phone number, and company details.</p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-4">3. How We Use Your Information</h2>
        <p>We utilize collected information to operate the app, improve user experience, develop new features, and for communication and marketing purposes.</p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-4">4. Log Files</h2>
        <p>We use standard log files to track app usage. Data collected includes IP addresses, device types, timestamps, and page interactions.</p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-4">5. CCPA & GDPR Rights</h2>
        <p>California consumers have rights to access and delete personal data. Users also have GDPR rights like data portability and erasure.</p>
      </section>

      <section>
        <h2 className="text-2xl font-semibold mt-4">6. Children's Information</h2>
        <p>ChariParking does not knowingly collect personal information from children under 13. Parents can contact us for data removal if needed.</p>
      </section>

    </div>
  );
};

export default PrivacyPolicy;