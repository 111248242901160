import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./components/Header";
import MidSection from './components/MidSection';
import PrivacyPolicy from './components/PrivacyPolicy';
import bg from "./images/bg.webp";


function App() {

  const styles = {
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    opacity: 0.6, // Adjust the opacity value (0.0 to 1.0) for transparency
  }

  return (
    <Router>
      <main className="h-screen w-screen" style={styles}>
        <Header />

        <div className="w-full xl:max-w-[1250px] mx-auto px-6">
          <Routes>
            <Route path="/" element={<MidSection />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </div>
      </main>
    </Router>
  );
}

export default App;