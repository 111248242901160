import React from 'react';
import parkingImage from '../images/logos.png';  // Assuming you have a logo image

const MidSection = () => {
  return (
      <div className="flex flex-col items-center w-full text-white">
          <div className="flex flex-col items-center">
              <h3 className="text-[10vw] sm:text-[4rem] font-medium">ChariParking</h3>
              <p className="text-[4vw] sm:text-[1.4rem] font-light text-center">
                  Find parking in crowded cities and support charities! Claim spots from the map, pay what you feel, and give back to the community.
              </p>
              <ul className="text-[3vw] sm:text-[1rem] font-light list-disc pl-10 text-center mt-4">
                  <li>Real-time parking spot tracking</li>
                  <li>Secure in-app payments</li>
                  <li>Supports various charitable causes</li>
                  <li>Reduces congestion and emissions</li>
              </ul>
              <button className="px-8 py-2 mt-10 bg-white rounded-3xl text-[#210535]">Download Now</button>
          </div>

          <div className="mt-6">
              <img src={parkingImage} alt="Parking available" className="w-[24rem]" />
          </div>
      </div>
  );
}

export default MidSection;
